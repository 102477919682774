import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import ArrowUpSvg from '../images/svg/arrow-up.svg'
import { animateScroll } from 'react-scroll'

const Container = styled.footer`
  width: 100%;
  padding: 12.5rem 0 ${ props => props.theme.pagePadding } 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  svg {
    height: 25px;
  }

  @media ${ props => props.theme.device.sm } {
    padding: 3.5rem 0 ${ props => props.theme.pagePaddingMobile } 0;

    svg {
      height: 20px;
    }
  }
`

const FooterLink = styled(GatsbyLink)`
  font-size: 1.5rem;
  line-height: 1.2;
  width: 20%;

  @media ${ props => props.theme.device.sm } {
    font-size: 1.125rem;
    width: 35%;
  }

  &:last-of-type {
    text-align: right;
  }
`

const ArrowUp = styled(ArrowUpSvg)`
  flex: 1;
  
  .arrow-up-stroke {
    stroke: ${ props => props.theme.dark.text };

    .light-theme & {
      stroke: ${ props => props.theme.light.text };
    }
  }
`

const Footer = () => {
  const scrollToTop = useCallback(() => {
    animateScroll.scrollToTop()
  }, [])

  return (
    <Container>
      <FooterLink to="/contact">Contact</FooterLink>
      <ArrowUp onClick={scrollToTop}/>
      <FooterLink
        as="a"
        href="https://www.instagram.com/studio_b2302/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </FooterLink>
    </Container>
  )
}

export default Footer
